import React from 'react';

const LogoSvg = ({ small }) => {
  const height = small ? '30' : '53.64';
  const width = small ? '212' : '378.576';
  return (
    <svg width={width} height={height} viewBox='0, 0, 378.576, 53.64'>
      <title>van Berzon</title>
      <g fill='currentColor' id='Layer_1'>
          <path d='M225.76,119.596 Q224.608,125.428 220.936,133.996 L212.944,152.5 L208.912,152.5 L195.88,119.596 L201.064,119.596 L211.216,145.372 L216.544,133.204 Q219.856,125.716 220.864,119.596 z'
          transform='translate(-195.88 -104.62)' />
          <path d='M246.568,118.876 Q253.624,118.876 257.332,122.548 Q261.04,126.22 261.04,135.004 L261.04,152.5 L255.424,152.5 L255.424,147.46 Q252.4,153.292 244.192,153.292 Q238.72,153.292 235.624,150.808 Q232.528,148.324 232.528,144.22 Q232.528,140.764 234.76,138.172 Q236.992,135.58 240.772,134.176 Q244.552,132.772 249.088,132.772 Q253.192,132.772 256.504,133.204 Q256.072,127.66 253.588,125.392 Q251.104,123.124 246.208,123.124 Q243.76,123.124 241.492,124.06 Q239.224,124.996 237.352,126.796 L234.832,123.484 Q239.44,118.876 246.568,118.876 z M244.696,149.332 Q250.024,149.332 253.12,146.2 Q256.216,143.068 256.504,137.164 Q253.264,136.66 249.52,136.66 Q244.048,136.66 240.88,138.46 Q237.712,140.26 237.712,143.932 Q237.712,149.332 244.696,149.332 z'
          transform='translate(-195.88 -104.62)' />
          <path d='M270.184,119.596 L275.224,119.596 L275.224,125.428 Q277.168,122.404 280.12,120.604 Q283.072,118.804 286.384,118.804 Q290.776,118.804 293.404,122.152 Q296.032,125.5 296.032,132.556 L296.032,152.5 L290.992,152.5 L290.992,132.7 Q290.992,127.804 289.336,125.572 Q287.68,123.34 284.944,123.34 Q282.712,123.34 280.444,124.636 Q278.176,125.932 276.7,128.272 Q275.224,130.612 275.224,133.564 L275.224,152.5 L270.184,152.5 z'
          transform='translate(-195.88 -104.62)' />
          <path d='M303.52,158.26 L303.52,153.868 L334.552,153.868 L334.552,158.26 z'
          transform='translate(-195.88 -104.62)' />
          <path d='M356.152,153.292 Q353.2,153.292 350.68,151.96 Q348.16,150.628 346.432,148.18 L344.632,152.5 L341.32,152.5 L341.32,104.62 L347.224,104.62 L347.224,105.124 Q346.792,105.556 346.684,106.06 Q346.576,106.564 346.576,107.788 L346.576,124.636 Q348.16,121.972 350.86,120.388 Q353.56,118.804 356.368,118.804 Q362.56,118.804 366.232,123.124 Q369.904,127.444 369.904,135.724 Q369.904,141.34 367.96,145.336 Q366.016,149.332 362.848,151.312 Q359.68,153.292 356.152,153.292 z M355.504,148.684 Q359.392,148.684 361.984,145.624 Q364.576,142.564 364.576,136.012 Q364.576,129.676 362.164,126.472 Q359.752,123.268 355.648,123.268 Q351.616,123.268 349.096,126.868 Q346.576,130.468 346.576,136.156 Q346.576,148.684 355.504,148.684 z'
          transform='translate(-195.88 -104.62)' />
          <path d='M392.584,153.22 Q385.24,153.22 380.884,148.756 Q376.528,144.292 376.528,136.156 Q376.528,130.756 378.544,126.832 Q380.56,122.908 383.98,120.856 Q387.4,118.804 391.648,118.804 Q397.624,118.804 401.332,122.764 Q405.04,126.724 405.04,134.428 Q405.04,135.22 404.896,136.804 L381.496,136.804 Q381.784,142.852 384.88,145.948 Q387.976,149.044 392.728,149.044 Q397.912,149.044 401.224,145.372 L404.104,148.18 Q399.928,153.22 392.584,153.22 z M399.784,132.772 Q399.784,128.164 397.444,125.464 Q395.104,122.764 391.288,122.764 Q387.688,122.764 385.024,125.284 Q382.36,127.804 381.712,132.772 z'
          transform='translate(-195.88 -104.62)' />
          <path d='M439.672,126.436 L439.672,126.508 Q437.584,124.564 436.18,123.916 Q434.776,123.268 432.904,123.268 Q430.312,123.268 428.008,124.564 Q425.704,125.86 424.264,128.488 Q422.824,131.116 422.824,135.076 L422.824,152.572 L417.568,152.572 L417.568,119.596 L422.968,119.596 L422.824,125.644 Q424.192,122.404 427.036,120.604 Q429.88,118.804 433.264,118.804 Q438.52,118.804 441.976,122.404 z M439.672,126.508 Q440.176,126.94 439.924,126.868 Q439.672,126.796 439.528,126.724 z M438.88,126.724 Q438.88,126.508 439.096,126.544 Q439.312,126.58 439.528,126.724 L439.384,127.084 L438.88,126.796 z'
          transform='translate(-195.88 -104.62)' />
          <path d='M448.6,148.972 L468.976,124.132 L450.112,124.132 L450.112,119.596 L475.672,119.596 L475.672,123.124 L455.656,148.036 L474.232,148.036 Q475.384,148.036 475.924,147.892 Q476.464,147.748 476.896,147.316 L477.4,147.316 L477.4,152.5 L448.6,152.5 z'
          transform='translate(-195.88 -104.62)' />
          <path d='M499,153.076 Q494.608,153.076 491.152,150.916 Q487.696,148.756 485.752,144.868 Q483.808,140.98 483.808,136.012 Q483.808,131.044 485.752,127.156 Q487.696,123.268 491.152,121.072 Q494.608,118.876 499,118.876 Q503.392,118.876 506.848,121.072 Q510.304,123.268 512.248,127.156 Q514.192,131.044 514.192,136.012 Q514.192,140.98 512.248,144.868 Q510.304,148.756 506.848,150.916 Q503.392,153.076 499,153.076 z M499,148.756 Q501.808,148.756 504.004,147.1 Q506.2,145.444 507.46,142.528 Q508.72,139.612 508.72,135.94 Q508.72,130.324 505.984,126.76 Q503.248,123.196 499,123.196 Q494.752,123.196 492.016,126.76 Q489.28,130.324 489.28,135.94 Q489.28,139.612 490.54,142.528 Q491.8,145.444 493.996,147.1 Q496.192,148.756 499,148.756 z'
          transform='translate(-195.88 -104.62)' />
          <path d='M522.184,119.596 L527.224,119.596 L527.224,125.428 Q529.168,122.404 532.12,120.604 Q535.072,118.804 538.384,118.804 Q542.776,118.804 545.404,122.152 Q548.032,125.5 548.032,132.556 L548.032,152.5 L542.992,152.5 L542.992,132.7 Q542.992,127.804 541.336,125.572 Q539.68,123.34 536.944,123.34 Q534.712,123.34 532.444,124.636 Q530.176,125.932 528.7,128.272 Q527.224,130.612 527.224,133.564 L527.224,152.5 L522.184,152.5 z'
          transform='translate(-195.88 -104.62)' />
          <path d='M570.136,130.324 Q568.48,130.324 567.184,129.1 Q565.888,127.876 565.888,126.22 Q565.888,124.492 567.148,123.268 Q568.408,122.044 570.136,122.044 Q571.864,122.044 573.16,123.268 Q574.456,124.492 574.456,126.22 Q574.456,127.876 573.16,129.1 Q571.864,130.324 570.136,130.324 z M570.136,153.292 Q568.336,153.292 567.112,152.104 Q565.888,150.916 565.888,149.188 Q565.888,147.46 567.148,146.236 Q568.408,145.012 570.136,145.012 Q571.864,145.012 573.16,146.236 Q574.456,147.46 574.456,149.188 Q574.456,150.844 573.196,152.068 Q571.936,153.292 570.136,153.292 z'
          transform='translate(-195.88 -104.62)' />
      </g>
    </svg>
  )
};

export { LogoSvg };